path {
    pointer-events: all;
    fill: white;
    stroke: black;
}

.chart-municipality:hover {
    stroke: green;
    stroke-width: 3;
}

/*path:nth-child(5) { fill: green; } path:nth-child(92) { fill: green; }*/
